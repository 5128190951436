<template>
  <v-form ref="form" v-model="form.valid">
    <v-dialog max-width="800px" value="true" persistent>
      <v-card>
        <v-card-title>新規コメント</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <template v-if="loginUserInfo.is_guest_user">
              <v-text-field label="記入者名" v-model="form.submit_login_guest_name" type="text"></v-text-field>
            </template>
            <v-textarea v-model="form.comment" :rules="form.comment_rules" class="required mt-3" label="コメント" outlined required auto-grow></v-textarea>
            <v-row>
              <v-col>
                <v-btn @click="addImageMemo" class="mr-3"><v-icon right class="mr-1">mdi-file-image-plus</v-icon>画面メモの追加</v-btn>
                <a href="https://aun.app/qqnpm" target="_blank" rel="noopener" class="text-decoration-none d-inline-block" style="font-size: 12px; color: rgba(0, 0, 0, 0.87);"><v-icon style="font-size: 14px;">mdi-help-circle</v-icon>使い方をみる</a>
              </v-col>
              <v-col>
                <v-file-input :rules="form.files_rules" v-model="form.files" class="my-0 py-0" small-chips multiple counter show-size label="ファイル添付" hint="複数選択可" persistent-hint ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="close" text>キャンセル</v-btn>
          <v-btn v-promise-btn color="primary" @click="send" min-width="15%">
            <template><v-icon left>mdi-comment-plus</v-icon>送信</template>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
      <v-overlay :value="isLoading" absolute>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  name: 'DirectionCommentDialog',
  props: { params: Object },
  data: () => ({
    isLoading: false,
    form: {
      valid: true,
      submit_login_guest_name: '',
      comment: '',
      comment_rules: [
        v => !!v || 'コメントは必ず入力してください'
      ],
      files: null,
      files_rules: [
        files => !files || !files.some(file => file.size > (150 * 1000 * 1000)) || 'サイズが大きすぎます' // NOTE: ディレクション管理は150MBまでに上限拡張
      ]
    }
  }),
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    }
  },
  watch: {
    'form.comment': function (newVal) {
      sessionStorage.setItem('comment', newVal)
    }
  },
  created: function () {
    if (this.loginUserInfo.is_guest_user) {
      this.form.submit_login_guest_name = this.loginUserInfo.name
    }
    this.form.comment = sessionStorage.getItem('comment') ?? ''
  },
  methods: {
    send: function () {
      const _this = this
      if (_this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('submit_login_guest_name', _this.form.submit_login_guest_name ?? '')
        formData.append('comment', _this.form.comment ?? '')
        if (_this.form.files) {
          for (const file of _this.form.files) {
            formData.append('files[]', file, file.name)
          }
        }
        _this.isLoading = true
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${_this.params.direction.id}/direction_comment`, formData)
            .then(res => {
              _this.form.comment = ''
              _this.$store.commit('setLoginUserName', _this.form.submit_login_guest_name)
              _this.$store.commit('setFlashSuccess', 'コメントを投稿しました。')
              _this.$emit('reload')
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(async () => {
              _this.isLoading = false
              _this.close()
            })
        })
      }
    },
    close: function () {
      this.$emit('close')
    },
    addImageMemo: function () {
      const _self = this
      let _vWin = null
      window.addEventListener('message', function addMessageFunc (event) {
        const _message = event.data
        if (typeof _message === 'string' || _message instanceof String) {
          if (_self.form.comment.length !== '') {
            _self.form.comment += '\n'
          }
          _self.form.comment += event.data
          _vWin.close()
          this.removeEventListener('message', addMessageFunc)
        }
      })
      _vWin = window.open(_self.$store.getters.getVisualRelayUrl, '_vWin')
      _vWin.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
