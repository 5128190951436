<template>
  <div>
    <v-card style="box-shadow: none;">
      <v-data-table disable-sort :headers="table_headers" :items-per-page="-1" :items="direction_dataoffers" class="elevation-0" hide-default-footer no-data-text="データは登録されていません">
        <template v-slot:item.direction_dataoffer_name="{ item }">
          <span>{{ item.direction_dataoffer_name }}</span>
        </template>
        <template v-slot:item.direction_dataoffer_receive="{ item }">
          <template v-if="item.direction_dataoffer_receive_label"><v-icon :color="item.direction_dataoffer_receive_color" class="mr-1">{{ item.direction_dataoffer_receive_icon }}</v-icon>{{ item.direction_dataoffer_receive_label }}</template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="loginUserInfo.is_admin_user" small @click="openEditDialog(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog persistent v-model="crud.dialog" max-width="500px">
      <v-form ref="form" v-model="crud.isFormValid" @submit.prevent>
        <v-card>
          <v-card-title v-if="crud.mode == 'new'">データ追加</v-card-title>
          <v-card-title v-else-if="crud.mode == 'edit'">データ編集</v-card-title>
          <v-divider />
          <v-card-text>
            <v-container class="pt-0">
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-text-field label="名前" type="text" v-model="crud.form.direction_dataoffer_name" :rules="[inputRules.required]" class="required"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select :items="crud.formOptions.direction_dataoffer_receive_items" item-value="value" item-text="label" label="受領状況" v-model="crud.form.direction_dataoffer_receive"></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="" text @click="closeDialog()">キャンセル</v-btn>
            <v-btn v-if="crud.mode == 'new'" v-promise-btn color="primary" @click="addDirectionDataoffer()" :disabled="!crud.isFormValid">保存</v-btn>
            <v-btn v-else-if="crud.mode == 'edit'" color="primary" v-promise-btn @click="updateDirectionDataoffer()" :disabled="!crud.isFormValid">保存</v-btn>
            <v-spacer />
            <confirm-dialog @accept="removeDirectionDataoffer()" v-if="crud.editedId !== -1">
              <template v-slot:title>このデータを削除しますか？</template>
              <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
              </template>
            </confirm-dialog>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'DirectionDataoffer',
  props: [
    'id'
  ],
  components: { ConfirmDialog },
  data: () => ({
    table_headers: [
      { text: '名前', value: 'direction_dataoffer_name' },
      { text: '状況', value: 'direction_dataoffer_receive' },
      { text: '', value: 'actions', cellClass: 'action' }
    ],
    direction_dataoffers: [],
    crud: {
      mode: 'new',
      dialog: false,
      editedId: -1,
      isFormValid: false,
      form: {
        direction_dataoffer_name: '',
        direction_dataoffer_receive: 0
      },
      defaultItem: {
        direction_dataoffer_name: '',
        direction_dataoffer_receive: 0
      },
      formOptions: {
        direction_dataoffer_receive_items: [
          { label: '未受領', value: 0 },
          { label: '受領済み', value: 1 },
          { label: '不要', value: 2 }
        ]
      }
    }
  }),
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    }
  },
  created: function () {
    this.readDataFromAPI()
  },
  methods: {
    readDataFromAPI: function () {
      const self = this
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_dataoffer`)
        .then(res => {
          self.direction_dataoffers = res.data
        })
    },
    openEditDialog (item) {
      this.crud.mode = 'edit'
      this.crud.editedId = item.id
      this.crud.form = Object.assign({}, item)
      this.crud.dialog = true
    },
    openAddDialog () {
      this.crud.mode = 'new'
      this.crud.editedId = -1
      this.crud.form = Object.assign({}, this.crud.defaultItem)
      this.crud.dialog = true
    },
    closeDialog () {
      this.crud.editedId = -1
      this.crud.form = Object.assign({}, this.crud.defaultItem)
      this.crud.dialog = false
    },
    addDirectionDataoffer: function () {
      const self = this
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('direction_dataoffer_name', self.crud.form.direction_dataoffer_name ?? '')
        formData.append('direction_dataoffer_receive', self.crud.form.direction_dataoffer_receive ?? '')
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_dataoffer`, formData)
            .then((res) => {
              self.$store.commit('setFlashSuccess', 'データを登録しました。')
              resolve(res)
            })
            .catch(err => {
              self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
              reject(err)
            })
            .finally(() => {
              self.readDataFromAPI()
              self.closeDialog()
              self.$emit('change')
            })
        })
      }
    },
    updateDirectionDataoffer: function () {
      const self = this
      if (self.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('direction_dataoffer_name', self.crud.form.direction_dataoffer_name ?? '')
        formData.append('direction_dataoffer_receive', self.crud.form.direction_dataoffer_receive ?? '')
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_dataoffer/${self.crud.editedId}`, formData)
          .then(() => {
            self.$store.commit('setFlashSuccess', 'データを更新しました。')
          })
          .catch(err => {
            self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
          .finally(() => {
            self.readDataFromAPI()
            self.closeDialog()
            self.$emit('change')
          })
      }
    },
    removeDirectionDataoffer: function () {
      const self = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/direction/${self.id}/direction_dataoffer/${self.crud.editedId}`)
        .then(() => {
          self.$store.commit('setFlashSuccess', 'データを削除しました。')
        })
        .catch(err => {
          self.$store.commit('setFlashError', self.createApiValidErrorMessage(err.response))
        })
        .finally(() => {
          self.readDataFromAPI()
          self.closeDialog()
          self.$emit('change')
        })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep td.action {
  width: 30px;
}
::v-deep .v-data-table .v-chip {
  vertical-align: baseline;
}
</style>
